import { Center, Heading, useToast, useBreakpointValue,Button,Box,Text, VStack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";




function ActivateEmail({toggleProp}){

    let [activateMessage, setActivateMessage] = useState()
    let [errorActivateMessage, setErorActivateMessage] = useState()
    let toast = useToast()


    let navigate = useNavigate()
    let {uid, token} = useParams()  // Extract the uid parameter and the token parameter from the URL


    // Function to suppose to get django default csrf_token from cookie in browser when React is served on django
    function getCSRFToken(){
        let cookieValue = document.cookie.match(/csrftoken=([^ ;]+)/)
        return cookieValue ? cookieValue[1] : null
    }

    let csrftoken = getCSRFToken();
    

    useEffect((e)=>{
        let func = async(e)=>{

            // The "new Promise()" serve as a placeholder for an actual asynchronous operation 
            let pro = new Promise((resolve, reject)=>{
                }) 
            // This ensures any existing toasts are closed before displaying new ones
             toast.closeAll()
             toast.promise(pro, {
             loading: {
             title: "Activation pending",
             description: "Please wait"
             },

             })



            try{
                let response = await axios.post("https://testflyio-ancient.fly.dev/djoser/users/activation/", {
                    uid: uid,
                    token: token
                },{
                    headers: {
                        'X-CSRFToken': csrftoken
                    }
                })
                console.log("User account has been activated successfully", response.data)
                setActivateMessage(true)
                // The "new Promise()" serve as a placeholder for an actual asynchronous operation 
                let pro = new Promise((resolve, reject)=>{
                    resolve()
                })
                // This ensures any existing toasts are closed before displaying new ones
                toast.closeAll()
                toast.promise(pro, {
                success: {
                title: "Activation resolved",
                description: "Looks great"
                }
                })
                
            }
            catch(error){
                console.error("error activating user account", error.response.data)
                setErorActivateMessage(true)
                // The "new Promise()" serve as a placeholder for an actual asynchronous operation 
                let pro = new Promise((resolve, reject)=>{
                    reject()
                })
                // This ensures any existing toasts are closed before displaying new ones
                toast.closeAll()
                toast.promise(pro, {
                error: {
                title: "Activation failed",
                description: "Looks like we encountered a problem. Please wait a few minutes and try again. If the problem persist, please contact support@benasenso.site"            
                }
                })             
            }
        }
        func()
    }, [uid, token])



    let mobileView = useBreakpointValue({
        base: true,
        md: false
    })

    let tabletView = useBreakpointValue({
        md: true,
        lg: false
    })





    return(
        <div>
        
            <Center minHeight="100vh"  backgroundColor={toggleProp && "#18181b"} color={toggleProp && "white"}>
                <VStack>
              
              {activateMessage?
                <>
                <Heading fontSize={tabletView? "3xl": (mobileView? "large": "4xl")}>{activateMessage? "Your email has been verified.": "Error activating your account" }</Heading>
                <Text mt={5} fontSize={tabletView? "xl":(mobileView? "sm": "2xl")}> {activateMessage? "You can now sign in with your new account" : "You cannot sign in with your account. Please try again later!"} </Text>

                <Button mt={4} colorScheme="facebook" onClick={(e)=> navigate("/") } >Back to homepage</Button>
                </>
                :
                
                (errorActivateMessage?
                <>
                <Heading fontSize={tabletView? "3xl": (mobileView? "large": "4xl")}>Error activating your account</Heading>
                <Text mt={5} fontSize={tabletView? "xl":(mobileView? "sm": "2xl")}> You cannot sign in with your account. Please try again later!</Text>
                <Button mt={4} colorScheme="facebook" onClick={(e)=> navigate("/") } >Back to homepage</Button>
                </>
                :
                <>
                <Heading fontSize={tabletView? "3xl": (mobileView? "large": "4xl")}>Activating your account</Heading>
                <Text mt={5} fontSize={tabletView? "xl":(mobileView? "sm": "2xl")}> Please wait! </Text>
                </>
                )
                
                }
                

                </VStack>
            </Center>

        </div>
    )
}


export default ActivateEmail