import { Heading, useBreakpointValue,Card, Center, CardBody, CardFooter, HStack, Container, Text, VStack, Button, Box, Image, InputAddon, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogBody, AlertDialogHeader, AlertDialogCloseButton, InputGroup, InputRightElement, Checkbox, CardHeader, FormLabel } from "@chakra-ui/react"
import {faEye, faEyeSlash, faWarning} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, {useState, useEffect} from "react"
import {Formik, Form, Field, ErrorMessage } from "formik"
import {FormErrorMessage, FormControl, Input} from "@chakra-ui/react"
import * as Yup from 'yup'


import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import app from './firebase';

import { useNavigate } from "react-router-dom";

import {useSpring, animated} from 'react-spring'
import axios from 'axios'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { parse } from "@fortawesome/fontawesome-svg-core";





function Signin({successLogin, setsuccessLogin, selected, setSelected}){

  let mobileView = useBreakpointValue({
    base: true,
    lg: false
  })

  let props = useSpring({
    opacity: 1,
    from: {opacity: 0}
  })


  // This is to target the md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
  })



  let navigate = useNavigate()
  let [hover, sethover] = useState(false)
  let [alerterrorMessage, setAlerterrormessage] = useState("")
  let [alerterrorResponseMessage, seterrorResponseMessage] = useState("")
  let [visiblePass, setVisiblePass] = useState(false)
  let [login, setLogin] = useState(false)
  let [forgot, setforgot] = useState(false)

  //let [rememberMe, setRememberMe] = useState(false)
  

  let onClose = () => {
    setAlerterrormessage("")
  }


  let [initialValues, setInitialvalues] = useState({
    username: "",
    password: "",
    rememberMe: false
  })


  let validationSchema = Yup.object({
    username: Yup.string().required("Username required"),
    password: Yup.string().required("Password required"),
    rememberMe: Yup.string()
  })

  // Function to suppose to get django default csrf_token from cookie in browser when React is served on django
  function getCSRFToken(){
    let cookieValue = document.cookie.match(/csrftoken=([^ ;]+)/)
    return cookieValue ? cookieValue[1] : null
  }

  let csrftoken = getCSRFToken();


  let onSubmit = async(values, actions) =>{

    try{  

      let response= await axios.post("https://testflyio-ancient.fly.dev/djoser/token/login/", values, {
        headers:{
          'X-CSRFToken': csrftoken
        }
      })
      console.log("Signed in successfully", response.data.auth_token)
      setsuccessLogin(true)
      //storing user data in the browser's local storage
      values.rememberMe && localStorage.setItem("UserLogin", JSON.stringify(values))
      localStorage.setItem("UserAuthtoken", JSON.stringify(response.data.auth_token) )
      actions.setSubmitting(false)
      navigate("/dashboard")
      setSelected("Dashboard")
      actions.resetForm()
    }
    
    catch (error){
      console.error("There was an error signing in:", error.message)
      actions.setSubmitting(false)
      setAlerterrormessage(`Something went wrong, please try again later! ${error.message}`)
      seterrorResponseMessage(error.response.data.non_field_errors)
    }
  }




  // Getting the user data from the browser's local storage
  
  useEffect((e)=>{
    let storedData = localStorage.getItem("UserLogin")
    // Converting stored data back to Javascript object
    if (storedData){
    let parseUserData = JSON.parse(storedData)
    initialValues.username = parseUserData.username
    initialValues.password = parseUserData.password

    let storedUsername = parseUserData.username
    let storedPassword = parseUserData.password
    
      // axios.post("http://127.0.0.1:8000/djoser/token/login/", {storedUsername, storedPassword})
      // .then(response =>{
      //   console.log(" Success in getting stored Data")
      //   setsuccessLogin(response.data.auth_token)
      //   navigate("/dashboard")
      // }) 
      // .catch(error=>{
      //   console.error("Error getting stored data:", error.response.data.non_field_errors)
      // })
    }

 },[])


let [user, setUser] = useState(null)
let [token, setToken] = useState()


// This is just displaying user data on signin page for displaying purposes
 useEffect(()=>{

  let storedData = localStorage.getItem("UserAuthtoken")
  let parseUserData = JSON.parse(storedData)
  setToken(parseUserData)

  let funct = async ()=>{
   if (token){
    try{
      const response = await axios.get("https://testflyio-ancient.fly.dev/djoser/users/me/", {
        headers: {
          'Authorization': `token ${token}`,
          'X-CSRFToken': csrftoken
        }
      })
      setUser(response.data)
      console.log("success fetching:", response.data)
    }
    catch(error){
      console.error("Error fetching data", error)
    }
  }
  }

  funct();

 }, [token])


 


    return(
      
        
          <div>
                <VStack maxWidth="full" minHeight="100vh" alignItems="left" spacing={8} backgroundColor="gray.400"  >  

                {/* <h1>generated Data</h1>
                {message && message.map((f, index)=>{
                  return <div key={index}>
                      <h2>{f.employee}</h2>
                      <h3>{f.department}</h3>
                    </div>
                })} */}

                {/* {user ?(
                <div >
                <h1>Username: {user.username}</h1>
                <h1>Email: {user.email}</h1>
                </div>
                ):(
                  <h1>Loading...</h1>
                )} */}

                        
            <div className="flex items-center justify-center h-screen flex-grow container">
              <div className="bg-white p-8 shadow-md rounded-lg max-w-md w-full">

              <Heading marginBottom={8} fontSize="2xl">Log in to your account </Heading>

                {/*Creating Form here. nothing shoud interfere with the three f's ie Formik, formik sub and form*/}
                <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                >

                {(formik) => (

                <Form>
                  
                <FormControl isInvalid={!!formik.errors.username && formik.touched.username} >
                <FormLabel htmlFor="username">Username</FormLabel>
                <Field as={Input} placeholder="Enter your username..."  name="username" id="username"  />
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="username" />
                </FormErrorMessage>

                </FormControl>
                
 
                <FormControl isInvalid={!!formik.errors.password && formik.touched.password} mt={4} >
               
                <FormLabel htmlFor="password">Password</FormLabel>
                <InputGroup>
                <InputRightElement children={<FontAwesomeIcon icon={visiblePass? faEyeSlash: faEye} onClick={(e)=> setVisiblePass(!visiblePass)} cursor="pointer" />} />
                <Field as={Input} type={visiblePass? "text": "password"} placeholder="Enter your password..." name="password" />
                </InputGroup>
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="password"/>
                </FormErrorMessage>


                </FormControl>

                
              
                <HStack width="100%" justifyContent="space-between">
                <Field as={Checkbox} name="rememberMe" id="rememberMe">Remember me</Field>  
                <Text _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} mt={4}><span onClick={(e)=> navigate("/users/password/new")} style={{color: "#EE9972", fontWeight: "bold", textDecoration: "underline",cursor: "pointer", marginLeft:5}}>Forgot pasword?</span></Text>    
              
                </HStack>
              
            
                <Button type="submit" isLoading={formik.isSubmitting} mt={4} width="full" colorScheme="messenger"  fontWeight="bold" >Login</Button>
              

                </Form>
                )}
                </Formik>

              </div>
            </div>
              

                </VStack>


                <AlertDialog
                isOpen={alerterrorMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose}
                >

              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor= "#FF8A65">
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                Oops!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alerterrorMessage}<br/>
                {alerterrorResponseMessage}
            
            </AlertDialogBody>
            </AlertDialogContent>
            </AlertDialogOverlay>

            </AlertDialog>


               
            </div> 


                  
  
    )
  }


export default Signin