import Httprequest from "./CompoForDjango/Httprequest"
import Header from "./CompoForDjango/Header"
import Welcome from "./CompoForDjango/Welcome"
import AllBlogs from "./CompoForDjango/AllBlogs"
import BlogDetails from "./CompoForDjango/BlogDetails"

import Signin from "./CompoForDjango/Signin"
import Signup from "./CompoForDjango/Signup"
import Password from "./CompoForDjango/PasswordReset"
import PasswordConfirm from "./CompoForDjango/PasswordConfirm"
import ActivateEmail from "./CompoForDjango/ActivateEmail"

import NotFoundPage from "./CompoForDjango/NotFoundPage"
import Footer from "./CompoForDjango/Footer"
import { ChakraProvider } from "@chakra-ui/react"
import { Routes, Route } from "react-router-dom"
import { useEffect, useState } from "react"
import { useNavigate,useLocation } from "react-router-dom"
import axios from "axios"



function App(){

    let [selected, setSelected] = useState("Signin")

    let [successLogin, setsuccessLogin] = useState(false)
    let [token, setToken] = useState()
    let [blogId, setBlogId] = useState()


    let navigate = useNavigate()
    let location = useLocation()


    useEffect((e)=>{

        let userAuthtoken = localStorage.getItem("UserAuthtoken")
        let parseUserData = JSON.parse(userAuthtoken)
        setToken(parseUserData)
        
          if(location.pathname === "/dashboard" || location.pathname === "/all-blogs" || location.pathname.startsWith("/blog-details") && successLogin === false ){
                navigate("/signin")
                setsuccessLogin(false)
            }
     
    }, [])

    return(
        <div>
            <ChakraProvider>
            <Header successLogin={successLogin} setsuccessLogin={setsuccessLogin} selected={selected} setSelected={setSelected}/>

            <Routes>
                <Route path="/" element={<Signin successLogin={successLogin} setsuccessLogin={setsuccessLogin} selected={selected} setSelected={setSelected}  />}  />         
                <Route path="/signin" element={<Signin successLogin={successLogin} setsuccessLogin={setsuccessLogin} selected={selected} setSelected={setSelected} />}  />
                <Route path="/signup" element={<Signup />} />
                <Route path="/users/password/new" element={<Password />} />
                <Route path="/password/reset/confirm/:uid/:token" element={<PasswordConfirm />} />    {/* Will be expecting uid and token in the path name */}
                <Route path="/activate/:uid/:token" element={<ActivateEmail />} />      {/* Will be expecting uid and token in the path name */}
                <Route path="*" element={<NotFoundPage />} />

            {successLogin && 
            <>
            <Route path="/dashboard" element={<Welcome successLogin={successLogin} setsuccessLogin={setsuccessLogin} />}  />                
            <Route path="/all-blogs" element={<AllBlogs successLogin={successLogin} setsuccessLogin={setsuccessLogin} blogId={blogId} setBlogId={setBlogId} />} />
            <Route path="/blog-details/:id" element={<BlogDetails successLogin={successLogin} setsuccessLogin={setsuccessLogin} blogId={blogId} setBlogId={setBlogId} />} />        {/* Will be expecting id in the path name */}
            </>

            }                

            </Routes>
            
            <Footer />

            </ChakraProvider>
            
        </div>
    )
}


export default App