import { Heading, useBreakpointValue,Card, Center, CardBody, CardFooter, HStack, Container, Text, VStack, Button, Box, Image, InputAddon, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogBody, AlertDialogHeader, AlertDialogCloseButton, InputGroup, InputRightElement, Checkbox, CardHeader, FormLabel } from "@chakra-ui/react"
import {faWarning} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, {useState, useEffect} from "react"
import {Formik, Form, Field, ErrorMessage } from "formik"
import {FormErrorMessage, FormControl, Input} from "@chakra-ui/react"
import * as Yup from 'yup'


import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import app from './firebase';

import { useNavigate } from "react-router-dom";

import {useSpring, animated} from 'react-spring'
import axios from 'axios'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'





function Password({successLogin, setsuccessLogin}){

  let mobileView = useBreakpointValue({
    base: true,
    md: false
  })



  // This is to target the md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
    lg: false
  })



  let navigate = useNavigate()
  let [alertMessage, setAlertmessage] = useState("")
  let [alerterrorMessage, setAlerterrormessage] = useState("")

  

  let onClose = () => {
    setAlerterrormessage("")
  }


  let [initialValues, setInitialvalues] = useState({
    email: "",
  })


  let validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Email required"),
  })


  
  // Function to suppose to get django default csrf_token from cookie in browser when React is served on django
  function getCSRFToken(){
    let cookieValue = document.cookie.match(/csrftoken=([^ ;]+)/)
    return cookieValue ? cookieValue[1] : null
  }

  let csrftoken = getCSRFToken();


  // No authorization token required for djoser reset password endpoint
  let onSubmit = async(values, actions) =>{
    // let token = '450f1a988a818c59ed81784e02667a89b2e196d7'
    try{
      let response= await axios.post("https://testflyio-ancient.fly.dev/djoser/users/reset_password/", values,{
        headers: {
          //'Authorization': `token ${token}`,
          'X-CSRFToken': csrftoken
        }
      })
      console.log("Password reset intructions sent to email")
      actions.setSubmitting(false)
      setAlertmessage(`Instructions for your Password reset has been sent successfully to ${values.email}, Check your email`)
      setTimeout(() => {
       setAlertmessage(false)
      }, 9000);
      actions.resetForm()
    }

    catch (error){
      actions.setSubmitting(false)
      setAlerterrormessage(`Something went wrong, please try again later! ${error.message}`)
    }
  }






    return(
      
        
          <div>
                <VStack maxWidth="full" p={mobileView && 3} minHeight="100vh" alignItems="left" spacing={8} backgroundColor="gray.400"  >  


                      
                <Card className={mobileView? "max-w-2xl mx-auto my-auto":"max-w-md mx-auto my-auto"} size="sm" p={5} borderRadius={8}  >
               
                <CardHeader>
                <Heading  marginBottom={5}  fontSize="2xl">Forgot Password. </Heading>
                <Text >Enter your email address, and we'll send you an email with instructions for how to reset your password</Text>
                </CardHeader>


                <CardBody>
                {/*Creating Form here. nothing shoud interfere with the three f's ie Formik, formik sub and form*/}
                <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                >

                {(formik) => (

                <Form>
            
                <FormControl isInvalid={!!formik.errors.email && formik.touched.email} >
               
                <FormLabel htmlFor="password">Email</FormLabel>
                <Field as={Input} placeholder="Email address" name="email" width={mobileView? "100%":"sm"}  />
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="email"/>
                </FormErrorMessage>

                </FormControl>
            
                <Button type="submit" isLoading={formik.isSubmitting} mt={4} width="full" colorScheme="messenger"  fontWeight="bold" >Send Instructions</Button>
              
                </Form>
                )}
                </Formik>

                </CardBody>

               
                </Card>

                </VStack>


                <AlertDialog
                isOpen={alertMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose}
                >

                <AlertDialogOverlay>
              <AlertDialogContent backgroundColor= "#81C784">
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                All good!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alertMessage}<br/>
            
              </AlertDialogBody>
              </AlertDialogContent>
              </AlertDialogOverlay>

              </AlertDialog>


                <AlertDialog
                isOpen={alerterrorMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose}
                >

              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor= "#FF8A65">
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                Oops!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alerterrorMessage}<br/>
            
            </AlertDialogBody>
            </AlertDialogContent>
            </AlertDialogOverlay>

            </AlertDialog>


      
            </div> 

    )
  }


export default Password