import { Heading, useBreakpointValue,Card, Center, CardBody, CardFooter, HStack, Container, Text, VStack, Button, Box, Image, InputAddon, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogBody, AlertDialogHeader, AlertDialogCloseButton, InputGroup, InputRightElement, Checkbox, CardHeader, FormLabel } from "@chakra-ui/react"
import {faEye, faEyeSlash, faWarning} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, {useState, useEffect} from "react"
import {Formik, Form, Field, ErrorMessage } from "formik"
import {FormErrorMessage, FormControl, Input} from "@chakra-ui/react"
import * as Yup from 'yup'
import {ref} from 'yup'


import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import app from './firebase';
import axios from "axios";

import { useNavigate } from "react-router-dom";

import {useSpring, animated} from 'react-spring'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { getDatabase } from "firebase/database";
import { addDoc, collection, doc,getFirestore,setDoc } from "firebase/firestore";





function Signup({setsuccessfulSignin, successfulSignin}){


  
  let auth = getAuth(app)
  let dataB = getFirestore(app)


  
  let mobileView = useBreakpointValue({
    base: true,
    lg: false
  })

  let props = useSpring({
    opacity: 1,
    from: {opacity: 0}
  })


  // This is to target the md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
  })



  let navigate = useNavigate()
  let [alertMessage, setAlertmessage] = useState("")
  let [alerterrorMessage, setAlerterrromessage] = useState("")
  let [alerterrorResponseMessage, seterrorResponseMessage] = useState("")
  let [visiblePass, setVisiblePass] = useState(false)
  

  //let [rememberMe, setRememberMe] = useState(false)
  

  let onClose = ()=>{
    setAlertmessage("")
  }
  
  let onClose2 = () => {
    setAlerterrromessage("")
  }


  let initialValues = {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    rememberMe: false
  }


  let validationSchema = Yup.object({
    username: Yup.string().required("Username required"),
    email: Yup.string().required("Email required").email("Invalid email format"),
    password: Yup.string().required("Password required").min(8, "Password must be at least 8 characters").matches(/[0-9]/, "Password must contain at least one number").matches(/[!@#$%^&*-_]/, "Password must contain at least one special character"),
    confirmPassword: Yup.string().required("Confirm password required").oneOf([ref("password"), null], "Passwords must match"),
    rememberMe: Yup.string()
  })


  
  // Function to suppose to get django default csrf_token from cookie in browser when React is served on django
  function getCSRFToken(){
    let cookieValue = document.cookie.match(/csrftoken=([^ ;]+)/)
    return cookieValue ? cookieValue[1] : null
  }

  let csrftoken = getCSRFToken();

  
  let onSubmit = async(values, actions)=>{

    try{
     let response = await axios.post("https://testflyio-ancient.fly.dev/djoser/users/", values, {
      headers:{
        'X-CSRFToken': csrftoken
      }
     })
     console.log("Form submitted successfully")
     actions.setSubmitting(false)
     actions.resetForm()
     setAlertmessage(`Thanks for your submission ${values.username}, You have successfully registered on AI Blog! Check your email for the verification link `)
     setTimeout(() => {
      setAlertmessage(false)
     }, 9000)
    }

    catch (error){
      console.error("Error submitting data", error.message)
      actions.setSubmitting(false)
      setAlerterrromessage(`Something went wrong, please try again later! ${error.message}`)
      seterrorResponseMessage(error.response.data.username)
      setTimeout(() => {
        setAlerterrromessage(false)
      }, 9000);
    }
  }




    return(
      
        
          <div>
                <VStack maxWidth="full" minHeight="100vh" padding={mobileView?3: 20} alignItems="left" spacing={8} backgroundColor="gray.400"  >  
                  

              <div className="flex items-center justify-center h-screen">
                <div className="bg-white p-8 shadow-md rounded-lg max-w-md w-full">
                <Heading marginBottom={8} fontSize="2xl">Signup </Heading>

                <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                >

                {(formik) => (

                <Form>

                <FormControl isInvalid={!!formik.errors.username && formik.touched.username} >
                <FormLabel htmlFor="username">Username</FormLabel>
                <Field as={Input} type="username" placeholder="Enter your username..." name="username"  />
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="username" />
                </FormErrorMessage>

                </FormControl>

                <FormControl isInvalid={!!formik.errors.email && formik.touched.email} >
                <FormLabel htmlFor="emali">Email</FormLabel>
                <Field as={Input} type="email" placeholder="Enter your email..." name="email"  />
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="email" />
                </FormErrorMessage>

                </FormControl>
                
 
                <FormControl isInvalid={!!formik.errors.password && formik.touched.password} mt={4} >               
                <FormLabel htmlFor="password">Password</FormLabel>
                <InputGroup>
                <InputRightElement children={<FontAwesomeIcon icon={visiblePass? faEyeSlash: faEye} onClick={(e)=> setVisiblePass(!visiblePass)} cursor="pointer" />} />
                <Field as={Input} type={visiblePass? "text": "password"} placeholder="Enter your password..." name="password" />
                </InputGroup>
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="password"/>
                </FormErrorMessage>

                </FormControl>


                <FormControl isInvalid={!!formik.errors.confirmPassword && formik.touched.confirmPassword} mt={4} >               
                <FormLabel htmlFor="password">Confirm Password</FormLabel>
                <Field as={Input} type="password" placeholder="Confirm your password..." name="confirmPassword" />
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="confirmPassword"/>
                </FormErrorMessage>

                </FormControl> 

            
                <Button type="submit" isLoading={formik.isSubmitting} mt={4} width="full" colorScheme="messenger"  fontWeight="bold" >Register</Button>
              

                </Form>
                )}
                </Formik>

                </div>
              </div>


                </VStack>


                
                <AlertDialog
                isOpen={alertMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose}
                >

              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor= "#81C784">
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                All good!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alertMessage}<br/>
            
              </AlertDialogBody>
              </AlertDialogContent>
              </AlertDialogOverlay>

              </AlertDialog>
                  
                
                
                <AlertDialog
                isOpen={alerterrorMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose2}
                >

              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor= "#FF8A65">
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                Oops!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5} >
                {alerterrorMessage}<br/>
                {alerterrorResponseMessage && alerterrorResponseMessage}
            </AlertDialogBody>
            </AlertDialogContent>
            </AlertDialogOverlay>

            </AlertDialog>


             
               
            </div> 


                  
  
    )
  }


export default Signup