import { Container, Text, HStack, Box, SkeletonText, useBreakpointValue, Link, Heading, VStack, Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { format } from "date-fns"
import axios from "axios"


function BlogDetails({blogId, setBlogId}){

    let [userBlogDetails, setUserBlogDetails] = useState()


    useEffect((e)=>{
        let func = async(e)=>{
            try{
                let response = await axios.get(`https://testflyio-ancient.fly.dev/api/blog-details/${blogId}`, {
                    headers:{
                        'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`
                    }
                })
                console.log("User blog details fetched successfully:", response.data)
                setUserBlogDetails(response.data)
            }
            catch (error){
                console.error("Error fetching user blog details", error.response.data)
            }
        }

        func()

    }, [])



    
    let mobileView = useBreakpointValue({
        base: true,
        md: false
    })


    let tabletView = useBreakpointValue({
        md:true,
        lg:false
    })




    return(
        <div className={mobileView? "mx-auto px-3 bg-gray-300 sm:px-0": "mx-auto px-4 bg-gray-300 sm:px-0"}>
            <VStack maxWidth="full" minHeight="100vh" alignItems="left" spacing={8}  p={mobileView?0: 10}  >  
            
            <div className={mobileView? "bg-white pt-4 pb-4 pl-2 pr-2 rounded-lg shadow-lg" :"bg-white p-8 pl-4 pr-4 rounded-lg shadow-lg"} style={{marginTop:120, marginBottom:120}}>

    
                <Tabs>
                    <TabList ml={4} mr={4}>
                        <Tab>Blog</Tab>
                        <Tab>Transcript</Tab>
                        {/* <Tab>Three</Tab> */}
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                        <Heading as="h2" size={mobileView? "lg":"xl"} >Blog Post Details</Heading>
                        {/* <Heading>{blogId}</Heading> */}
            
                    {userBlogDetails?
                        <div className="space-y-4 my-4">
                            <div className="border border-black p-4 rounded-lg">
                                <HStack flexDirection={mobileView && "column"} alignItems={mobileView && "start"} width="100%" justifyContent="space-between" >  
                                <h3 className={mobileView? "text-xl font-semibold":"text-2xl font-semibold"}>{userBlogDetails.youtube_title}</h3>
                                <h3 className={mobileView? "text-lg font-semibold":"text-xl font-semibold"}>{format(userBlogDetails.created_at, "yyyy-MM-dd 'at' hh:mm a")}</h3>
                                </HStack>
                                <p dangerouslySetInnerHTML={{__html:userBlogDetails.generated_blog }} className={mobileView? "text-lg text-gray-700":"text-xl text-gray-700"}></p>
                                <hr className="my-4 border-gray-700" />
                                <h4 className={mobileView? "text-xl font-semibold":"text-2xl font-semibold"}>Youtube Title</h4>
                                <p className={mobileView? "text-lg text-gray-700":"text-xl text-gray-700"}>{userBlogDetails.youtube_title}</p>
                                <h4 className={mobileView? "text-xl font-semibold mt-4": "text-2xl font-semibold mt-4"}>Youtube Link</h4>
                                <Link color="rgb(37 99 235)" isExternal href={userBlogDetails.youtube_link} className={mobileView? "text-xl text-blue-600 hover:underline":"text-2xl text-blue-600 hover:underline"}>{userBlogDetails.youtube_link}</Link>

                                {/* <h4 className={mobileView? "text-xl font-semibold mt-4": "text-2xl font-semibold mt-4"}>Youtube Audio</h4>
                                <audio controls>
                                    <source src={userBlogDetails.audio_file} type="audio/mpeg"  />
                                </audio> */}


                            </div>
                        </div>

                        :

                        <>
                        <Box padding='6' boxShadow="lg" >
                            <SkeletonText mt='4' noOfLines={8} spacing='4' skeletonHeight='4' />
                        </Box>

                        <Box padding='6' boxShadow="lg" >
                            <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='4' />
                        </Box>
                        </>

                        }
    
                        </TabPanel>
                        <TabPanel>
                        <Heading as="h2" size={mobileView? "lg":"xl"} >Transcript Details</Heading>
                        {/* <Heading>{blogId}</Heading> */}
            
                    {userBlogDetails?
                        <div className="space-y-4 my-4">
                            <div className="border border-black p-4 rounded-lg">
                                <h3 className={mobileView? "text-xl font-semibold":"text-2xl font-semibold"}>{userBlogDetails.youtube_title}</h3>
                                <p dangerouslySetInnerHTML={{__html:userBlogDetails.transcript }} className={mobileView? "text-lg text-gray-700":"text-xl text-gray-700"}></p>
                                <hr className="my-4 border-gray-700" />
                                <h4 className={mobileView? "text-xl font-semibold":"text-2xl font-semibold"}>Youtube Title</h4>
                                <p className={mobileView? "text-lg text-gray-700":"text-xl text-gray-700"}>{userBlogDetails.youtube_title}</p>
                                <h4 className={mobileView? "text-xl font-semibold mt-4": "text-2xl font-semibold mt-4"}>Youtube Link</h4>
                                <Link color="rgb(37 99 235)" isExternal href={userBlogDetails.youtube_link} className={mobileView? "text-xl text-blue-600 hover:underline":"text-2xl text-blue-600 hover:underline"}>{userBlogDetails.youtube_link}</Link>

                                <h4 className={mobileView? "text-xl font-semibold mt-4": "text-2xl font-semibold mt-4"}>Youtube Audio</h4>
                                <audio controls>
                                    <source src={userBlogDetails.audio_file} type="audio/mpeg"  />
                                </audio>


                            </div>
                        </div>

                        :

                        <>
                        <Box padding='6' boxShadow="lg" >
                            <SkeletonText mt='4' noOfLines={8} spacing='4' skeletonHeight='4' />
                        </Box>

                        <Box padding='6' boxShadow="lg" >
                            <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='4' />
                        </Box>
                        </>

                        }
    
                        
                        </TabPanel>
                    </TabPanels>
                </Tabs>        

         

          
            </div>


            </VStack>


        </div>
    )
}


export default BlogDetails